import Text from "./Text";

function UvIndex(props) {
    return (
        <div>
            現在紫外線指數
            <Text value={props.value} />
            (<Text value={props.description} />)
        </div>
    );
}

export default UvIndex;