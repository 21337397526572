import './LocationList.css'

function LocationList(props) {
    const options = props.locations.map(l => (<option key={l} value={l}>{l}</option>))
    return (
        <select className="LocationList" value={props.location} onChange={handleChange}>
                <option value="">請選擇地區</option>
                {options}
        </select>
    );
    function handleChange(e) {
        props.onLocationChange(e.target.value);
    }
}

export default LocationList;