import LocationList from "./LocationList";
import Text from "./Text";

function Temperature(props) {
    return (
        <div>
            現在
            <LocationList location={props.location} locations={props.list.map(t => t.location)} onLocationChange={props.onChange} />
            温度
            <Text value={props.value} />
            °C
        </div>
    );
}

export default Temperature;