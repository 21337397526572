import { useEffect, useState } from 'react';
import Temperature from './Temperature';
import Rainfall from './Rainfall';
import UvIndex from './UvIndex';
import Humidity from './Humidity';
import './App.css';

function App() {
  const [temperatureLocation, setTemperatureLocation] = useState('');
  const [temperatureList, setTemperatureList] = useState([]);
  const [rainfallLocation, setRainfallLocation] = useState('');
  const [rainfallList, setRainfallList] = useState([]);
  const [humidity, setHumidity] = useState(0);
  const [uvIndex, setUvIndex] = useState(0);
  const [uvIndexDesc, setUvIndexDesc] = useState('');

  useEffect(() => {
    setTemperatureLocation(localStorage.getItem('temperatureLocation') || '');
    setRainfallLocation(localStorage.getItem('rainfallLocation') || '');
    const hko_api_url = 'https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=tc';
    const request = new Request(hko_api_url, {method: 'GET'});
    fetch(request)
      .then(response => response.json())
      .then(data => {
        setTemperatureList(data.temperature.data.map(d => ({location: d.place, temperature: d.value})));
        setRainfallList(data.rainfall.data.map(d => ({location: d.place, min: d.min, max: d.max})));
        setHumidity(data.humidity.data[0].value);
        setUvIndex(data.uvindex ? data.uvindex.data[0].value : 0);
        setUvIndexDesc(data.uvindex ? data.uvindex.data[0].desc : '-');
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('temperatureLocation', temperatureLocation);
  }, [temperatureLocation]);

  useEffect(() => {
    localStorage.setItem('rainfallLocation', rainfallLocation);
  }, [rainfallLocation]);
  
  const temperatureObj = temperatureList.find(t => t.location===temperatureLocation);
  const temperature = temperatureObj ? temperatureObj.temperature : 0;

  const rainfallObj = rainfallList.find(t => t.location===rainfallLocation);
  const rainfallMin = (rainfallObj && rainfallObj.min) ? rainfallObj.min : 0;
  const rainfallMax = (rainfallObj && rainfallObj.max) ? rainfallObj.max : 0;

  return (
    <div className="App">
      <Temperature location={temperatureLocation} value={temperature} list={temperatureList} onChange={handleTemperatureLocationChange} />
      <br />
      <Rainfall min={rainfallMin} max={rainfallMax} location={rainfallLocation} list={rainfallList} onChange={handleRainfallLocationChange} />
      <br />
      <UvIndex value={uvIndex} description={uvIndexDesc} />
      <br />
      <Humidity value={humidity} />
    </div>
  );

  function handleTemperatureLocationChange(l) {
    setTemperatureLocation(l);
  }

  function handleRainfallLocationChange(l) {
    setRainfallLocation(l);
  }
}


export default App;
