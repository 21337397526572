import Text from "./Text";

function Humidity(props) {
    return (
        <div>
            現在濕度
            <Text value={props.value} />
            %
        </div>
    );
}

export default Humidity;