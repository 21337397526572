import LocationList from "./LocationList";
import Text from "./Text";

function Rainfall(props) {
    return (
        <div>
            過去一小時
            <LocationList location={props.location} locations={props.list.map(r => r.location)} onLocationChange={props.onChange} />
            下雨
            <Text value={props.min} />
            mm
            至
            <Text value={props.max} />
            mm
        </div>
    );
}

export default Rainfall;